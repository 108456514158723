<template>
    <v-container fluid>
        <v-col cols="12" class="text-center">
            <v-row :class="{ 'mt-10': !$vuetify.breakpoint.mdAndUp, 'mt-10 pt-10': $vuetify.breakpoint.mdAndUp }">
                <!-------------- esporta lead  ----------------->

                <v-col cols="4" class="d-flex justify-center">
                    <v-card flat>
                        <div class="text-center" @click="confirmExportLeads" v-if="leads.length">
                            <v-btn rounded color="orange" class="d-none d-md-flex" large
                                ><v-icon class="pa-2">download</v-icon> {{ $t('esporta', { page: this.$tc('lead', 2) }) }}
                            </v-btn>
                            <v-icon color="orange " class="pointer d-flex d-md-none mt-5" x-large>download</v-icon>
                        </div>
                    </v-card>
                </v-col>

                <!-------------- title ----------------->
                <v-col cols="4" class="d-flex justify-center">
                    <h1>
                        {{ $t('sezione', { page: this.$tc('pulisci-db', 1) }) }}
                    </h1>
                </v-col>

                <!-------------- filter ----------------->
                <v-col cols="4" class="d-flex justify-center">
                    <div @click="modalFilter = !modalFilter">
                        <v-btn large rounded color="success" class="d-none d-md-flex">
                            <v-icon class="pa-2">filter_alt</v-icon> {{ $t('imposta-filtri') }}
                        </v-btn>
                        <v-icon color="success" class="d-flex d-md-none mt-5" x-large>filter_alt</v-icon>
                    </div>

                    <div class="text-center">
                        <v-bottom-sheet v-model="modalFilter">
                            <v-sheet class="text-center" height="auto">
                                <!-------------- reset filter ----------------->
                                <v-container cols="12">
                                    <v-row class="d-flex justify-center">
                                        <!-- Close button -->
                                        <v-col cols="3" class="d-flex justify-center" @click="modalFilter = !modalFilter">
                                            <v-btn class="mt-n8 d-none d-sm-flex" color="error">{{ $t('chiudi') }}</v-btn>
                                            <v-btn color="error" class="d-flex d-sm-none icon"><v-icon> cancel </v-icon></v-btn>
                                        </v-col>

                                        <!-- Reset filters button -->
                                        <v-col cols="6">
                                            <v-btn class="mt-n8" color="warning" @click="clearFilters">{{ $t('reset-filtri') }}</v-btn>

                                            <h1 class="mt-4">{{ $tc('filtro', 2) }}</h1>
                                        </v-col>

                                        <!-- Apply button -->
                                        <v-col cols="3" class="d-flex justify-center" @click="setFilter()">
                                            <v-btn class="mt-n8 d-none d-sm-flex" color="success">{{ $t('applica') }}</v-btn>
                                            <v-btn color="success" class="d-flex d-sm-none icon"> <v-icon> search </v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div class="pa-6">
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-checkbox v-model="filters.Name" :label="`Nome duplicato`"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-checkbox v-model="filters.Address" :label="`Indirizzo duplicato`"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-checkbox v-model="filters.City" :label="`Citta duplicato`"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-checkbox v-model="filters.Province" :label="`Provincia duplicato`"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-checkbox v-model="filters.CAP" :label="`CAP duplicato`"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-checkbox v-model="filters.Phone" :label="`Telefono duplicato`"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-checkbox v-model="filters.Coordinate" :label="`Senza Coordinate`"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-checkbox v-model="filters.Norelated" :label="`No Padri e figli`"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-sheet>
                        </v-bottom-sheet>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <!------------- GRIGLIE ----------------->
        <v-card class="marginScroll">
            <v-data-table
                :headers="headers"
                :items="leads"
                :options.sync="options"
                :loading="loading"
                class="pt-6"
                :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100, 500, 1000], showFirstLastPage: true }"
                v-model="selectedLeads"
                :mobile-breakpoint="1270"
                :item-class="itemRowBackground"
                show-select
            >
                <template v-slot:top>
                    <v-menu transition="slide-y-transition" bottom v-if="selectedLeads.length" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="ml-6 mt-n2 mb-4" v-bind="attrs" v-on="on"> {{ $tc('azione', 2) }} </v-btn>
                            {{ selectedLeads.length }}
                            {{ $tc('lead', 1) }} {{ $tc('selezionato', selectedLeads.length > 1 ? 2 : 1) }}
                        </template>
                        <v-list class="text-uppercase">
                            <v-list-item
                                v-if="$permission($auth.utente.role.name, 'FullLeadTable') && (selectedLeads.length == 2 || selectedLeads.length == 3)"
                                @click="merge()"
                            >
                                <v-list-item-title class="ma-5 pointer"
                                    ><v-icon color="black" class="mb-1" left> merge </v-icon> {{ $t('unifica') }}
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="showDeleteDialogSelect" v-if="$permission($auth.utente.role.name, 'deleteLead')">
                                <v-list-item-title class="ma-5 pointer"
                                    ><v-icon color="black" class="mb-1" left> delete </v-icon> {{ $t('elimina') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="updatelocation" v-if="$permission($auth.utente.role.name, 'deleteLead')">
                                <v-list-item-title class="ma-5 pointer"
                                    ><v-icon color="black" class="mb-1" left> my_location </v-icon> {{ $t('aggiorna-coordinate') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template #[`item.Name`]="{ item, value }">
                    <span>
                        <a :href="`${baseUrl}/lead/${item.id}`" target="_blank">
                            {{ value }}
                        </a>
                    </span>
                </template>

                <template #[`item.Email`]="{ value }">
                    <span v-if="value">
                        <a :href="'mailto:' + value"><v-icon>mail</v-icon></a>
                    </span>
                </template>

                <template #[`item.Opening`]="{ value }">
                    <v-btn :disabled="!$hasOpening(value)" color="primary" @click="showAllOpen(value)">
                        {{ $tc('orario', 1) }}
                    </v-btn>
                </template>
                <template #[`item.zones`]="{ value }">
                    <span>{{ getAgents(value) }}</span>
                </template>
                <template #[`item.edit_agents`]="{ value }">
                    <span>{{ value.map((x) => x.Name).join(', ') }}</span>
                </template>
            </v-data-table>
            <v-dialog v-model="showAllOpenDialog" max-width="500">
                <v-card>
                    <v-card-title class="headline justify-center">{{ $tc('orario', 2) }}</v-card-title>
                    <v-card-text>
                        <Opening v-model="selectedOpening" />
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="error" @click="showAllOpenDialog = false">{{ $tc('chiudi') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="showDeleteConfirmation" max-width="500">
                <v-card outlined>
                    <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                    <v-card-text class="mt-6, text-center mt-6"> {{ deleteDialogMsg }}</v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn text @click="cancelDelete">{{ $t('annulla') }}</v-btn>
                        <v-spacer></v-spacer>

                        <v-btn color="red" text @click="deleteL(deleteDialogCall)">{{ $t('elimina') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
        <!-- dialog sicuro di voler cancellare -->

        <v-dialog v-model="showExportConfirmation" max-width="500">
            <v-card outlined>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                <v-card-text class="mt-6, text-center mt-6"> Stai esportanto più di {{ limitNumberExport }} leads sei sicuro?</v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="showExportConfirmation = false">{{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn color="red" text @click="exportLeads">{{ $t('esporta') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Merge v-model="openmerge" :pid="mergeLeads" :types="['leads', 'leads', 'leads']" :details="[null, null, null]"></Merge>
    </v-container>
</template>

<script>
import Opening from '@/components/Opening.vue'
import Merge from '@/components/merge.vue'

export default {
    name: 'ClearDB',
    components: { Opening, Merge },
    data() {
        return {
            leads: [],
            totalLeads: 0,
            loading: false,
            options: {
                page: 1,
                itemsPerPage: 100,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: null,
                mustSort: null
            },
            timeoutQuery: null,
            selectedOpening: [],
            showAllOpenDialog: false,
            showDeleteConfirmation: false,
            showExportConfirmation: false,
            limitNumberExport: 1000,
            deleteDialogCall: null,
            leadToDelete: null,
            modalFilter: true,
            baseUrl: '',

            headers: [
                { text: this.$tc('nome-azienda', 1), value: 'Name', field: 'Name', sortable: true },
                {
                    text: this.$tc('indirizzo', 1),
                    value: 'Address',
                    field: 'Address',
                    sortable: true
                },
                {
                    text: this.$tc('citta', 1),
                    value: 'City',
                    field: 'City.Name',
                    sortable: true
                },
                {
                    text: this.$tc('cap', 1),
                    value: 'CAP',
                    field: 'CAP',
                    sortable: true
                },
                {
                    text: this.$tc('provincia', 1),
                    value: 'Province',
                    field: 'Province.Name',
                    sortable: true
                },
                {
                    text: this.$tc('telefono', 1),
                    value: 'Phone',
                    field: 'Phone',
                    sortable: true
                },
                {
                    text: this.$tc('orario', 1),
                    value: 'Opening',
                    sortable: false,
                    field: 'Opening'
                },
                {
                    text: this.$tc('categoria', 1),
                    value: 'Category',
                    field: 'Category',
                    sortable: true
                },
                /* {
                    text: this.$tc('zona', 1),
                    value: 'zones.data',
                    field: 'zones',
                    sortable: false
                },*/
                {
                    text: 'csvCategory',
                    value: 'csvCategory',
                    field: 'csvCategory',
                    sortable: true
                },
                {
                    text: this.$t('assegnato-ad-agente'),
                    value: 'agents',
                    sortable: false,
                    field: 'agents'
                },
                {
                    text: this.$t('versione-dell-agente'),
                    value: 'edit_agents',
                    sortable: false,
                    field: 'edit_agents'
                }
            ],

            selectedLeads: [],
            changinurl: false,

            addto: [],

            selectDay: null,
            loadingDay: false,

            mergeLeads: [],
            openmerge: false,

            filters: {
                Name: true,
                Address: false,
                City: false,
                Province: false,
                CAP: false,
                Phone: false,
                Coordinate: false,
                Norelated: false
            },
            deleteDialogMsg: ''
        }
    },
    watch: {},
    async created() {
        if (!this.$permission(this.$auth.utente.role.name, 'AdminPage')) {
            this.$router.push('/')
        }
        this.baseUrl = window.location.origin
        this.changinurl = true
        /*if (this.$route.query.page) {
            this.options.page = parseInt(this.$route.query.page)
        }
        if (this.$route.query.items) {
            this.options.itemsPerPage = parseInt(this.$route.query.items)
        }*/
        /*if (this.$route.query.sortBy) {
            this.options.sortBy[0] = this.$route.query.sortBy
            if (this.$route.query.sortDesc) {
                this.options.sortDesc[0] = this.$route.query.sortDesc
            }
        }*/
        /*if (this.$route.query.cap) {
            this.selectCaps = this.$route.query.cap.split(',')
        }

        if (this.$route.query.provincia) {
            await this.getProvinceFromApiId(this.$route.query.provincia)
        }
        if (this.$route.query.citta) {
            await this.getCitiesFromApiId(this.$route.query.citta)
        }
        if (this.$route.query.zona) {
            await this.getZoneFromApiId(this.$route.query.zona)
        }
        if (this.$route.query.cat) {
            this.selectCat = this.$route.query.cat.split(',').map((x) => {
                if (x == 'empty') {
                    return ''
                } else {
                    return x
                }
            })
        }
        if (this.$route.query.sli) {
            let sli = this.$route.query.sli.split(',')
            let c = 0
            for (const x in this.sliders) {
                this.sliders[x].value = sli[c]

                c++
            }
        }*/

        //await this.getDataFromApi()
        //await this.getCitiesFromApi('')
        this.changinurl = false
    },
    methods: {
        merge() {
            let errlead = false
            this.selectedLeads.forEach((x) => {
                const child = x.child_leads.filter((y) => {
                    return y.disabled == false
                })
                if (child.length) {
                    if (this.selectedLeads.length > 2) {
                        errlead = true
                    } else {
                        for (const c of child) {
                            if (!this.selectedLeads.map((l) => l.id).includes(c.id)) {
                                errlead = true
                                break
                            }
                        }
                    }
                }
            })

            if (errlead) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('impossibile-unificare-dei-lead-con-derivati-unificare-prima-i-derivati'),
                    type: 'error'
                })
                return
            }

            this.mergeLeads = this.selectedLeads.map((x) => x.id)
            this.openmerge = true
        },
        showDeleteDialogSelect() {
            this.deleteDialogMsg = this.$t('sei-sicuro-di-voler-eliminare-i-lead-selezionati')
            this.deleteDialogCall = false
            this.showDeleteConfirmation = true
        },
        showAllOpen(value) {
            this.selectedOpening = this.$opening2UI(value)

            this.showAllOpenDialog = true
        },
        getAgents(zones) {
            if (!zones?.length) return ''
            let agents = new Set()
            zones.forEach((z) => {
                if (!z.agents?.length) return
                z.agents.forEach((a) => {
                    agents.add(a.Name)
                })
            })
            return [...agents].join(',')
        },
        clearFilters() {
            // Reset all filter
            this.filters = {
                Name: true,
                Address: false,
                City: false,
                Province: false,
                CAP: false,
                Phone: false
            }
        },

        addTo(id) {
            if (!id) {
                this.addto = this.selectedLeads.map((x) => x.id)
            } else {
                this.addto = [id]
            }
        },
        async updateUrl() {
            if (this.changinurl) return

            let query = { ...this.$route.query }
            this.changinurl = true
            /*if (this.options.page > 1) {
                if (this.$route.query.page != this.options.page) {
                    query.page = this.options.page
                }
            } else {
                delete query.page
            }
            if (this.options.itemsPerPage > 10) {
                if (this.$route.query.items != this.options.itemsPerPage) {
                    query.items = this.options.itemsPerPage
                }
            } else {
                delete query.items
            }
            if (this.options.sortBy.length) {
                if (this.$route.query.sortBy != this.options.sortBy) {
                    query.sortBy = this.options.sortBy
                    if (this.options.sortDesc.length && this.$route.query.sortDesc != this.options.sortDesc) {
                        query.sortDesc = this.options.sortDesc
                    }
                }
            } else {
                delete query.sortBy
                delete query.sortDesc
            }

            if (this.selectProv.length) {
                if (this.$route.query.provincia != this.selectProv.map((x) => x.id).join(',')) {
                    query.provincia = this.selectProv.map((x) => x.id).join(',')
                }
            } else {
                delete query.provincia
            }

            if (this.selectZona.length) {
                if (this.$route.query.zona != this.selectZona.map((x) => x.id).join(',')) {
                    query.zona = this.selectZona.map((x) => x.id).join(',')
                }
            } else {
                delete query.zona
            }

            if (this.selectCity.length) {
                if (this.$route.query.citta != this.selectCity.map((x) => x.id).join(',')) {
                    query.citta = this.selectCity.map((x) => x.id).join(',')
                }
            } else {
                delete query.citta
            }

            if (this.selectCaps.length) {
                if (this.$route.query.cap != this.selectCaps.join(',')) {
                    query.cap = this.selectCaps.join(',')
                }
            } else {
                delete query.cap
            }
            if (this.selectCat.length) {
                if (
                    this.$route.query.cat !=
                    this.selectCat
                        .map((x) => {
                            if (x == '') {
                                return 'empty'
                            } else {
                                return x
                            }
                        })
                        .join(',')
                ) {
                    query.cat = this.selectCat
                        .map((x) => {
                            if (x == '') {
                                return 'empty'
                            } else {
                                return x
                            }
                        })
                        .join(',')
                }
            } else {
                delete query.cat
            }
            let sli = []
            let found = false
            for (const x in this.sliders) {
                if (this.sliders[x].value > 0) {
                    found = true
                }
                sli.push(this.sliders[x].value)
            }
            if (found) {
                if (this.$route.query.sli != sli.join(',')) {
                    query.sli = sli.join(',')
                }
            } else {
                delete query.sli
            }

            this.$router
                .replace({
                    path: this.$route.path,
                    query: query
                })
                .catch((err) => {})
*/
            await this.getDataFromApi()
            this.changinurl = false
        },

        setFilter() {
            this.options.page = 1
            this.selectedLeads = []
            this.updateUrl()
            this.modalFilter = false
        },

        async confirmExportLeads() {
            if (this.totalLeads >= this.limitNumberExport) {
                this.showExportConfirmation = true
            } else {
                await this.exportLeads()
            }
        },

        async exportLeads() {
            try {
                this.loading = true

                let csv = `${this.headers
                    .map((x) => {
                        if (x.field != 'Opening') {
                            return x.text
                        }
                    })
                    .join(';')}\n`

                let cc = {}
                let pa = 1
                //do {
                cc = await this.$http.get('leadutil', this.filters)
                //esportare i dati salvarti nel template del file
                cc.forEach((x) => {
                    if (!x) return
                    let row = ''
                    this.headers.forEach((h) => {
                        let t = this.$getObjValue(x, h.value)
                        if (h.field == 'Opening') {
                            return
                        }
                        if (h.value == 'edit_agents') {
                            t = t.map((x) => x.Name).join(', ')
                        }
                        row += `${t == null ? '' : t};`
                    })

                    csv += `${row.slice(0, -1)}\n`
                })
                //pa++
                //} while (cc.meta.pagination.pageCount > cc.meta.pagination.page)

                this.loading = false
                this.showExportConfirmation = false

                const anchor = document.createElement('a')
                anchor.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
                anchor.target = '_blank'
                anchor.download = `cleardb-${this.$moment().format('YYMMDD')}.csv`
                anchor.click()
            } catch (error) {
                /*this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-durante-la-richiesta-di-importazione-dei-leads'),
                    type: 'error'
                })*/
                console.error(error)
            }
        },

        async getDataFromApi() {
            this.loading = true

            const docs = await this.$http.get('leadutil', this.filters)

            this.leads = docs.data || docs
            //console.log(this.leads)
            //this.totalLeads = docs.meta.pagination.total ? docs.meta.pagination.total : 0
            this.loading = false
        },
        async updatelocation() {
            try {
                //await this.$http.delete(`leads/${this.leadToDelete}`)
                await this.$http.post(`updatelocation`, {
                    list: this.selectedLeads.map((l) => l.id)
                })
                this.$notify({
                    group: 'notifica',
                    title: this.$t('successo'),
                    text: this.$t('aggiornamento-coordinate-iniziato-riceverai-una-email-al-completamento'),
                    type: 'success'
                })
            } catch (error) {
                console.error(error)

                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-il-recupero-delle-coordinate'),
                    type: 'error'
                })
            }
        },
        itemRowBackground(item) {
            if (item.validCat == 'missing') {
                return 'tab-lblue'
            } else if (!item.validCap) {
                return 'tab-purple'
            } else if (item.ischild) {
                return 'tab-red'
            } else if (item.isagentlead) {
                return 'tab-yellow'
            }
        },
        async deleteSelect() {
            if (!this.$permission(this.$auth.utente.role.name, 'deleteLead')) {
                return
            }

            try {
                for (const lead of this.selectedLeads) {
                    //await this.$http.delete(`leads/${lead.id}`)
                    await this.$http.put(`leads/${lead.id}`, {
                        data: { disabled: true }
                    })
                    /*await this.$http.post(`actionlogs`, {
                        data: { agent: this.$auth.utente.agent.id, lead: lead.id, action: 'disable', date: this.$moment().format() }
                    })*/
                }

                this.getDataFromApi()
                this.selectedLeads = []
                this.cancelDelete()
                this.$notify({
                    group: 'notifica',
                    title: this.$t('successo'),
                    text: this.$t('leads-selezionati-cancellati-con-successo'),
                    type: 'success'
                })
            } catch (error) {
                console.error(error)
                this.cancelDelete()
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-dei-leads-selezionati'),
                    type: 'error'
                })
            }
        },
        cancelDelete() {
            this.showDeleteConfirmation = false
            this.leadToDelete = null
            this.deleteDialogMsg = ''
            this.deleteDialogCall = false
        },
        deleteL(type = false) {
            if (type) {
                this.deleteLead()
            } else {
                this.deleteSelect()
            }
        },
        async deleteLead() {
            if (!this.$permission(this.$auth.utente.role.name, 'deleteLead')) {
                return
            }
            try {
                //await this.$http.delete(`leads/${this.leadToDelete}`)
                await this.$http.put(`leads/${this.leadToDelete}`, {
                    data: { disabled: true }
                })
                /*await this.$http.post(`actionlogs`, {
                    data: { agent: this.$auth.utente.agent.id, lead: this.leadToDelete, action: 'disable', date: this.$moment().format() }
                })*/

                await this.getDataFromApi()

                this.cancelDelete()
            } catch (error) {
                console.error(error)

                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-del-lead'),
                    type: 'error'
                })

                this.cancelDelete()
            }
        }
    }
}
</script>
<style>
/* Stile CSS personalizzato */
.no-underline {
    text-decoration: none;
}
.icon {
    width: 20px;
}
h4 {
    text-align: center;
    text-transform: uppercase;
}
</style>
