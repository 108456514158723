<template>
    <v-container fluid>
        <!-------------- title
                ----------------->
        <v-row class="text-center">
            <v-col cols="12" class="mb-n6">
                <h1 :class="{ 'mt-15': !$vuetify.breakpoint.mdAndUp, 'mt-10 pt-10': $vuetify.breakpoint.mdAndUp }">
                    {{ $t('sezione', { page: this.$tc('agente', 2) }) }}
                </h1>
            </v-col>
            <v-col cols="12">
                <v-card class="d-flex justify-center mb-6" flat>
                    <!-------------- new agente
                ----------------->
                    <v-row style="justify-content: center">
                        <template v-if="$permission($auth.utente.role.name, 'editUsers')">
                            <v-col cols="2" sm="3" class="d-flex justify-center">
                                <router-link to="/user/new" class="text-decoration-none">
                                    <v-btn rounded large color="success " class="d-none d-md-flex">
                                        <v-icon class="pa-2">add_circle</v-icon> {{ $t('crea-', { page: this.$tc('agente', 1) }) }}
                                    </v-btn>
                                    <v-icon color="success" class="d-flex d-md-none" x-large>add_circle</v-icon>
                                </router-link>
                            </v-col>
                        </template>

                        <v-col v-if="!$permission($auth.utente.role.name, 'editUsers')" cols="2" sm="3" class="d-flex justify-center"> </v-col>

                        <!-------------- search 
                ----------------->

                        <v-col cols="8" sm="6" class="d-flex justify-center mb-4">
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                :label="$t('ricerca-nome')"
                                single-line
                                hide-details
                                class=""
                                clearable
                            ></v-text-field>
                        </v-col>

                        <!-------------- filter----------------->

                        <v-col cols="2" sm="3" class="d-flex justify-center">
                            <div @click="modalFilter = !modalFilter">
                                <v-btn large rounded color="success" class="d-none d-md-flex">
                                    <v-icon class="pa-2">filter_alt</v-icon> {{ $t('imposta-filtri') }}
                                </v-btn>
                                <v-icon color="success" class="d-flex d-md-none" x-large>filter_alt</v-icon>
                            </div>
                        </v-col>
                        <div class="text-center">
                            <v-bottom-sheet v-model="modalFilter">
                                <v-sheet class="text-center" height="auto">
                                    <!-------------- reset filter ----------------->
                                    <v-container cols="12">
                                        <v-row class="d-flex justify-center">
                                            <!-- Close button -->
                                            <v-col cols="3" class="d-flex justify-center" @click="modalFilter = !modalFilter">
                                                <v-btn class="mt-n8 d-none d-sm-flex" color="error">{{ $t('chiudi') }}</v-btn>
                                                <v-btn color="error" class="d-flex d-sm-none icon"><v-icon> cancel </v-icon></v-btn>
                                            </v-col>

                                            <!-- Reset filters button -->
                                            <v-col cols="6">
                                                <v-btn class="mt-n8" color="warning" @click="clearFilters">{{ $t('reset-filtri') }}</v-btn>

                                                <h1 class="mt-4">FILTRI</h1>
                                            </v-col>

                                            <!-- Apply button -->
                                            <v-col cols="3" class="d-flex justify-center" @click="setFilter()">
                                                <v-btn class="mt-n8 d-none d-sm-flex" color="success">{{ $t('applica') }}</v-btn>
                                                <v-btn color="success" class="d-flex d-sm-none icon"> <v-icon> search </v-icon></v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                    <div class="pl-6 pr-6">
                                        <v-row style="justify-content: center">
                                            <v-col cols="12 " sm="6">
                                                <v-text-field v-model="emailFilter" :label="$tc('email', 1)" solo-inverted flat></v-text-field>
                                            </v-col>
                                            <template v-if="$permission($auth.utente.role.name, 'editUsers')">
                                                <v-col cols="12 " sm="6">
                                                    <v-autocomplete
                                                        :label="$tc('zona', 1)"
                                                        item-text="name"
                                                        :search-input.sync="searchZona"
                                                        cache-items
                                                        multiple
                                                        hide-no-data
                                                        v-model="selectZona"
                                                        :loading="loadingZona"
                                                        :items="Zone"
                                                        return-object
                                                        solo-inverted
                                                        flat
                                                        chips
                                                        deletable-chips
                                                        clearable
                                                    ></v-autocomplete>
                                                </v-col>
                                            </template>
                                        </v-row>
                                    </div>
                                </v-sheet>
                            </v-bottom-sheet>
                        </div>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <!------------- GRIGLIE
                ----------------->
        <v-card class="marginScroll">
            <v-data-table
                :headers="headers"
                :items="users"
                :options.sync="options"
                :server-items-length="totalUsers"
                :loading="loading"
                :search="search"
                class="pt-3 elevation-6"
                :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100] }"
                v-model="selectedUsers"
                @update:options="updateUrl"
                :mobile-breakpoint="1270"
            >
                <template v-slot:item.email="{ item }">
                    <td class="mailLowercase">{{ item.email }}</td>
                </template>
                <!-- pulsante azioni -->

                <template v-slot:top v-if="$permission($auth.utente.role.name, 'deleteUser')">
                    <v-menu transition="slide-y-transition" bottom v-if="selectedUsers.length" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="ml-6 mt-n2 mb-4" v-bind="attrs" v-on="on"> {{ $tc('azione', 2) }} </v-btn>
                        </template>
                        <v-list class="text-uppercase">
                            <v-list-item @click="showDeleteDialogSelect">
                                <v-list-item-title class="ma-4"
                                    ><v-icon color="black" class="mb-1" left> delete </v-icon> {{ $tc('elimina', 1) }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <!-------------
                     azione ----------------->

                <template #[`item.id`]="{ value }">
                    <v-container class="d-flex flex-row" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'" flat tile>
                        <router-link :to="`/user/${value}/edit`">
                            <v-icon class="pt-3 d-inline-flex"> edit </v-icon>
                        </router-link>
                        <router-link :to="`/user/${value}`">
                            <v-icon class="pa-3 d-inline-flex">visibility</v-icon>
                        </router-link>
                        <v-icon class="d-inline-flex" @click="showDeleteDialog(value)">cancel</v-icon>
                    </v-container>
                </template>

                <!-- Aggiunta colonna "DISABILITATO " -->

                <template #[`item.blocked`]="{ value }">
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-icon v-if="value" color="red">lock</v-icon>
                            <v-icon v-else color="green"> lock_open </v-icon>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <!-- dialogo eliminazione agente / utente -->
            </v-data-table>
        </v-card>
        <v-dialog v-model="showDeleteConfirmation" max-width="500">
            <v-card outlined>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                <v-card-text class="mt-6, text-center mt-6"> {{ deleteDialogMsg }}</v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="cancelDelete">{{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn color="red" text @click="deleteL(deleteDialogCall)">{{ $t('elimina') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'User',

    data() {
        return {
            users: [],
            totalUsers: 0,
            loading: true,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: null,
                mustSort: null
            },
            search: '',
            modalFilter: false,
            timeoutQuery: null,
            showDeleteConfirmation: false,
            deleteDialogMsg: '',
            deleteDialogCall: null,
            headers: [
                { text: this.$tc('nome', 1), value: 'name', field: 'Name' },
                { text: this.$tc('email', 1), value: 'email', field: 'user.email' },
                { text: this.$tc('ruolo', 1), value: 'role', field: 'role' },

                { text: this.$tc('zona', 2), value: 'zones', field: 'zones' },
                { text: this.$tc('lead-derivati', 1), value: 'leads', field: 'leads' },
                {
                    text: this.$tc('disabilitato', 1),
                    value: 'blocked',
                    field: 'user.blocked'
                }
            ],
            searchZona: null,
            selectZona: [],
            loadingZona: false,
            Zone: [],
            emailFilter: null,
            selectedUsers: [],
            changinurl: false
        }
    },
    watch: {
        search: {
            handler() {
                if (!this.search || this.search.length == 0) {
                    this.setFilter()
                    return
                }
                if (this.search.length < 3) {
                    return
                }
                if (this.timeoutQuery) {
                    clearTimeout(this.timeoutQuery)
                }
                this.timeoutQuery = setTimeout(() => {
                    this.setFilter()
                }, 300)
            }
        },
        searchZona(val) {
            val && this.getZone(val)
        }
    },
    async created() {
        if (!this.$permission(this.$auth.utente.role.name, 'AgentPage')) {
            this.$router.push('/')
        }
        this.changinurl = true
        if (this.$route.query.page) {
            this.options.page = parseInt(this.$route.query.page)
        }
        if (this.$route.query.items) {
            this.options.itemsPerPage = parseInt(this.$route.query.items)
        }
        if (this.$route.query.sortBy) {
            this.options.sortBy[0] = this.$route.query.sortBy
            if (this.$route.query.sortDesc) {
                this.options.sortDesc[0] = this.$route.query.sortDesc
            }
        }
        if (this.$route.query.search) {
            this.search = decodeURIComponent(this.$route.query.search)
        }
        if (this.$route.query.email) {
            this.emailFilter = decodeURIComponent(this.$route.query.email)
        }
        if (this.$route.query.zona) {
            await this.getZoneFromApiId(this.$route.query.zona)
        }
        //this.getDataFromApi()
        if (this.$permission(this.$auth.utente.role.name, 'editUsers')) {
            this.headers = [
                {
                    text: this.$tc('azione', 1),
                    align: 'start',
                    sortable: false,
                    value: 'id',
                    field: 'id'
                },
                ...this.headers
            ]
            if (this.$route.query.agente) {
                await this.getAgentsFromApiId(this.$route.query.agente)
            }
        }
        this.changinurl = false
    },
    methods: {
        clearFilters() {
            // Reset all filter
            this.searchZona = null
            ;(this.selectZona = []), (this.emailFilter = null)
        },
        updateUrl() {
            if (this.changinurl) return

            let query = { ...this.$route.query }
            this.changinurl = true
            if (this.options.page > 1) {
                if (this.$route.query.page != this.options.page) {
                    query.page = this.options.page
                }
            } else {
                delete query.page
            }
            if (this.options.itemsPerPage > 10) {
                if (this.$route.query.items != this.options.itemsPerPage) {
                    query.items = this.options.itemsPerPage
                }
            } else {
                delete query.items
            }
            if (this.options.sortBy.length) {
                if (this.$route.query.sortBy != this.options.sortBy) {
                    query.sortBy = this.options.sortBy
                    if (this.options.sortDesc.length && this.$route.query.sortDesc != this.options.sortDesc) {
                        query.sortDesc = this.options.sortDesc
                    }
                }
            } else {
                delete query.sortBy
                delete query.sortDesc
            }
            if (this.search) {
                if (this.$route.query.search != this.search) {
                    query.search = encodeURIComponent(this.search)
                }
            } else {
                delete query.search
            }
            if (this.emailFilter) {
                if (this.$route.query.email != this.emailFilter) {
                    query.email = encodeURIComponent(this.emailFilter)
                }
            } else {
                delete query.email
            }

            if (this.selectZona.length) {
                if (this.$route.query.zona != this.selectZona.map((x) => x.id).join(',')) {
                    query.zona = this.selectZona.map((x) => x.id).join(',')
                }
            } else {
                delete query.zona
            }

            this.$router
                .replace({
                    path: this.$route.path,
                    query: query
                })
                .catch((err) => {})
            this.getDataFromApi()
            this.changinurl = false
        },
        getLeads(zones) {
            if (!zones.data?.length) return 0
            let c = 0
            zones.data.forEach((z) => {
                c = c + z.attributes.leads.data.filter((x) => !x.attributes.parent_lead.data).length
            })
            return c
        },
        getZone(val) {
            if (val.length < 2) {
                return
            }
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getZoneFromApi(val), 300)
        },
        async getZoneFromApi(val) {
            this.loadingZona = true
            const p = await this.$http.get(`zones`, { 'pagination[pageSize]': 200, 'filters': { Name: { $containsi: val } } })
            if (!p.data.length) {
                this.loadingZona = false
                return
            }
            this.Zone = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.loadingZona = false
        },
        async getZoneFromApiId(val) {
            this.loadingZona = true
            let filters = {}
            let c = 0
            val.split(',').forEach((x) => {
                filters[`filters[$or][${c}][id][$eq]`] = x
                c++
            })
            let ci = []
            let cc = {}
            let pa = 1
            do {
                cc = await this.$http.get(`zones`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    ...filters
                })
                ci = ci.concat(cc.data)
                pa++
            } while (cc.meta.pagination.pageCount > cc.meta.pagination.page)
            if (!cc.data.length) {
                this.loadingZona = false
                return
            }
            this.Zone = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.selectZona = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.loadingZona = false
        },
        setFilter() {
            this.options.page = 1
            this.updateUrl()
            this.modalFilter = false
        },

        async deleteUserAgent() {
            try {
                await this.$http.delete(`agents/${this.agentToDelete}`)

                //await this.$http.delete(`users/${this.agentToDelete}`)

                // Aggiorna la tabella dopo l'eliminazione
                this.getDataFromApi()
            } catch (error) {
                console.error(error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-dellagente'),
                    type: 'error'
                })
            }

            this.cancelDelete()
        },
        showDeleteDialog(agentId, userId) {
            this.agentToDelete = agentId
            this.userToDelete = userId
            this.deleteDialogMsg = this.$t('sei-sicuro-di-voler-eliminare-lagente')
            this.deleteDialogCall = true
            this.showDeleteConfirmation = true
        },
        showDeleteDialogSelect() {
            this.deleteDialogMsg = this.$t('sei-sicuro-di-voler-eliminare-gli-agenti-selezionati')
            this.deleteDialogCall = false
            this.showDeleteConfirmation = true
        },
        deleteL(type = false) {
            if (type) {
                this.deleteUserAgent()
            } else {
                this.deleteSelect()
            }
        },
        cancelDelete() {
            this.showDeleteConfirmation = false
            this.leadToDelete = null
            this.deleteDialogMsg = ''
            this.deleteDialogCall = false
        },

        async getDataFromApi() {
            this.loading = true
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            let filters = {}

            let cc = 0
            if (this.search) {
                filters[`filters[$and][${cc}][Name][$containsi]`] = this.search
                cc++
            }
            if (this.selectZona) {
                let c = 0
                this.selectZona.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][zones][id][$eq]`] = x.id
                    c++
                })
                cc++
            }
            if (this.emailFilter) {
                filters[`filters[$and][${cc}][user][email][$containsi]`] = this.emailFilter
                cc++
            }

            if (!this.$permission(this.$auth.utente.role.name, 'FullUserTable')) {
                filters[`filters[$and][${cc}][user][role][name][$ne]`] = 'Super'
                cc++
            }

            if (this.$permission(this.$auth.utente.role.name, 'groupLeadTable')) {
                let c = 0
                this.$auth.utente.agent.agents.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][id][$eq]`] = x.id
                    c++
                })
                cc++
            }
            let sort = 'Name'
            if (sortBy.length) {
                const el = this.headers.find((x) => x.value == sortBy[0])
                sort = el.field.concat(sortDesc[0] ? ':desc' : '')
            }
            const docs = await this.$http.get('agentlist', {
                pageSize: itemsPerPage,
                page: page,
                populate: ['user', 'user.role', 'zones', 'zones.leads', 'zones.leads.parent_lead'],
                sort: sort,
                ...filters
            })

            this.users = docs.results || docs
            //console.log(this.users)
            this.totalUsers = docs.pagination.total ? docs.pagination.total : 0
            this.loading = false
        },
        async deleteSelect() {
            if (!this.$permission(this.$auth.utente.role.name, 'deleteUser')) {
                return
            }

            try {
                for (const agent of this.selectedUsers) {
                    await this.$http.delete(`agents/${agent.id}`)

                    //await this.$http.delete(`users/${agent.attributes.user.data.id}`)
                }

                this.getDataFromApi()
                this.selectedUsers = []
                this.cancelDelete()
                this.$notify({
                    group: 'notifica',
                    title: this.$t('successo'),
                    text: this.$t('agenti-selezionati-cancellati-con-successo'),
                    type: 'success'
                })
            } catch (error) {
                console.error(error)
                this.cancelDelete()
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-degli-agenti-selezionati'),
                    type: 'error'
                })
            }
        }
    }
}
</script>
<style>
.mailLowercase {
    text-transform: lowercase !important;
}
</style>
