<template>
    <div class="d-flex align-center justify-center" style="height: 100vh !important">
        <v-row style="width: 100% !important; display: flex; justify-content: center !important">
            <v-card v-show="type == 'login'" class="pa-4" max-width="400" max-height="500">
                <v-card-title class="d-flex justify-center">
                    <div>
                        <v-img max-height="150" max-width="200" src="img/logodam.png"></v-img>
                    </div>
                </v-card-title>
                <v-card-text class="mt-15">
                    <v-form ref="login">
                        <v-row>
                            <!-- email -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="email"
                                    class="mx-auto"
                                    :disabled="loading"
                                    :label="$t('email')"
                                    outlined
                                    @keydown.enter="signin"
                                    :rules="[$rules('email')].flat()"
                                    validate-on-blur
                                />
                            </v-col>

                            <!-- password -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="password"
                                    :disabled="loading"
                                    :label="$t('password')"
                                    outlined
                                    :append-icon="showPass ? 'visibility_off' : 'visibility'"
                                    :type="showPass ? 'text' : 'password'"
                                    @click:append="showPass = !showPass"
                                    @keydown.enter="signin"
                                />

                                <!-- remember me checkbox -->
                                <div class="d-flex align-center justify-space-between flex-wrap mb-2">
                                    <v-btn text class="d-inline" @click="cambiaTipo('recovery')">{{ $t('recupera-password') }}</v-btn>
                                </div>

                                <!-- login button -->
                                <v-btn
                                    color="primary"
                                    block-rounded="lg"
                                    size="x-large"
                                    :disabled="!email || !password"
                                    block
                                    :loading="loading"
                                    @click="signin"
                                >
                                    {{ $t('accedi') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
            <!-- ---------------------------------------------------------------------------------------------------------------
                  recupero password

            --------------------------------------------------------------------------------------------------------------- -->
            <v-card v-show="type == 'recovery'" class="pa-4 pt-7" max-width="448">
                <v-btn @click="cambiaTipo('login')" class="ma-2" color="orange-darken-2">
                    <v-icon size="15">arrow_back</v-icon>
                    {{ $t('torna-al-log-in') }}
                </v-btn>
                <v-card-title class="justify-center">
                    <div class="d-block pa-2 b mt-10" align="center">
                        <v-img max-height="150" max-width="150" src="img/logodam.png"></v-img>
                    </div>
                </v-card-title>

                <v-card-text class="pt-2 mt-10">
                    <h5 class="text-h5 font-weight-semibold mb-1 text-center">{{ $t('recupera-password') }}</h5>
                </v-card-text>
                <v-card-text>
                    <v-form ref="forgot">
                        <v-row>
                            <!-- email -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="email"
                                    class="mt-5 mx-auto"
                                    :disabled="loading"
                                    :label="$t('email')"
                                    outlined
                                    :rules="[$rules('email')].flat()"
                                    validate-on-blur
                                />
                                <div class="d-flex justify-space-between bg-surface-variant flex-row-reverse"></div>
                            </v-col>

                            <!-- login button -->
                            <v-btn color="primary" block-rounded="lg" size="x-large" :disabled="!email" block :loading="loading" @click="forgotPassword">
                                {{ $t('invia') }}
                            </v-btn>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>

            <!-- ---------------------------------------------------------------------------------------------------------------
                  nuova password 

            --------------------------------------------------------------------------------------------------------------- -->
            <v-card v-show="type == 'new'" class="pa-4 pt-7" max-width="448">
                <v-card-title class="justify-center">
                    <v-img max-height="150" max-width="200" src="img/logodam.png"></v-img>
                </v-card-title>

                <v-card-text class="pt-2">
                    <h5 class="text-h5 font-weight-semibold mb-1">{{ $t('nuova-password') }}</h5>
                    <v-btn @click="cambiaTipo('login')" class="ma-2" color="orange-darken-2">
                        <v-icon size="15">arrow_back</v-icon>
                        {{ $t('torna-al-log-in') }}
                    </v-btn>
                </v-card-text>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <!-- PASSWORD -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="password"
                                    :disabled="loading"
                                    :label="$t('password')"
                                    outlined
                                    :append-icon="showPass ? 'visibility_off' : 'visibility'"
                                    :type="showPass ? 'text' : 'password'"
                                    @click:append="showPass = !showPass"
                                />
                            </v-col>
                            <!--CONFERMA PASSWORD -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="confPassword"
                                    :disabled="loading"
                                    :label="$t('conferma-password')"
                                    outlined
                                    :append-icon="showPass ? 'visibility_off' : 'visibility'"
                                    :type="showPass ? 'text' : 'password'"
                                    @click:append="showPass = !showPass"
                                />
                            </v-col>

                            <!-- login button -->
                            <v-btn color="primary" block-rounded="lg" size="x-large" :disabled="!password" block :loading="loading" @click="resetPassword">{{
                                $t('recupera-password')
                            }}</v-btn>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row class="d-flex align-center justify-center">
            <v-alert v-if="message.text" border="left" text dense :type="message.class" class="ma-0 mb-5">
                <small>{{ message.text }}</small>
            </v-alert>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'SignIn',
    data() {
        return {
            email: '',
            password: '',
            confPassword: '',
            code: null,
            loading: false,
            showPass: false,
            type: null,
            message: { text: null, class: 'error' }
        }
    },
    props: {
        ptype: { type: String, default: 'login' }
    },
    created() {
        this.type = this.ptype
        this.code = this.$route.query.code
    },
    methods: {
        async signin() {
            if (!this.$refs.login.validate()) {
                this.message.text = this.$t('inserire-una-email-valida')
                this.message.class = 'error'
                return
            }
            this.loading = true
            this.text = this.$t('prepariamo-la-gestione')

            try {
                await this.$auth.signIn(this.email, this.password, true)

                setTimeout(() => {
                    this.$router.push('/').catch((err) => {
                        console.log(err)
                    })
                    this.loading = false
                }, 1000)
            } catch (err) {
                console.log(err)
                this.message = {
                    text: this.$t('nome-utente-o-password-errata'),
                    class: 'error'
                }
                this.loading = false
            }
        },

        async forgotPassword(e) {
            if (!this.$refs.forgot.validate()) {
                this.message.text = this.$t('inserire-una-email-valida')
                this.message.class = 'error'
                return
            }
            this.loading = true
            this.text = this.$t('prepariamo-la-gestione')

            try {
                await this.$auth.forgotPassword(this.email)

                setTimeout(() => {
                    //success
                    this.message = {
                        text: this.$t('abbiamo-ricevuto-la-richiesta-controlla-la-casella-di-posta'),
                        class: 'success'
                    }
                    this.loading = false
                }, 1000)
            } catch (err) {
                console.log(err)
                this.message = {
                    text: this.$t('errore-riprovare'),
                    class: 'error'
                }
                this.loading = false
            }
        },
        async resetPassword(e) {
            this.loading = true

            this.text = this.$t('prepariamo-la-gestione')

            try {
                if (!this.$auth.stringEqual(this.password, this.confPassword)) {
                    //password diverse
                    this.message.text = this.$t('password-non-uguale-inserire-password-uguali')
                    this.message.class = 'error'
                    this.loading = false
                    return
                }
                if (this.password.length < 6) {
                    this.message.text = this.$t('password-troppo-corta-minimo-6-caratteri')
                    this.message.class = 'error'
                    this.loading = false
                    return
                }
                await this.$auth.resetPassword(this.password, this.confPassword, this.code)

                setTimeout(() => {
                    //success
                    this.message.text = this.$t('password-aggiornata-ritorna-al-login')
                    this.message.class = 'success'
                    this.loading = false
                }, 1000)
            } catch (err) {
                console.log(err)

                this.message = {
                    text: this.$t('errore-richiedere-nuova-mail'),
                    class: 'error'
                }
                this.loading = false
            }
        },
        cambiaTipo(nuovoTipo) {
            if (this.type == 'new' && nuovoTipo == 'login') {
                this.$router.push({ path: '/signin' }).then(() => {
                    this.$router.go()
                })
                return
            }
            this.type = nuovoTipo
            this.password = ''
            this.confPassword = ''
            this.code = ''
            this.message.text = ''
            this.message.class = ''
        }
    }
}
</script>
<style>
h5 {
    text-transform: uppercase;
}
</style>
