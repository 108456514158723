import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Http from '@/assets/libs/Http'
import Auth from '@/assets/libs/Auth'
import Notifications from 'vue-notification'
import moment from 'moment'
import '@/assets/custom.css'
import VueSocketIO from 'vue-socket.io'
import i18n from './i8ln'
import utils from './mixins/utils'

Vue.config.productionTip = false
const http = new Http(process.env.VUE_APP_API, 'cpdam', {
    auths: '/api/auth',
    globals: '/api/',
    users: '/api/users',
    agents: '/api/agents',
    agentlist: '/api/agent/list',
    cities: '/api/cities',
    provinces: '/api/provinces',
    zones: '/api/zones',
    zonelist: '/api/zone/list',
    leads: '/api/leads',
    roles: '/api/users-permissions/roles',
    importcsv: '/api/importcsv',
    leadsByRule: '/api/leads-by-rule',
    leadsByRules: '/api/leads-by-rules',
    leadToChecks: '/api/lead-to-checks',
    plans: '/api/plans',
    tags: '/api/tags',
    reminders: '/api/reminders',
    setting: '/api/setting',
    rprovince: '/api/reportprovince',
    location: '/api/location',
    categories: '/api/categories',
    leadutil: '/api/lead/dbutil',
    updatelocation: '/api/updatecoord/location',
    actionlogs: 'api/actionlogs',
    direction: '/api/location/direction'
})

Vue.prototype.$eventHub = new Vue()
Vue.prototype.$http = http
Vue.prototype.$auth = new Auth('cpdam', http)
Vue.prototype.$moment = moment
Vue.use(Notifications)
Vue.use(
    new VueSocketIO({
        debug: false,
        connection: process.env.VUE_APP_API,
        vuex: {
            store,
            actionPrefix: 'SOCKET_',
            mutationPrefix: 'SOCKET_'
        },
        options: { withCredentials: false }
    })
)
/*Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})*/
Vue.mixin(utils)

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
