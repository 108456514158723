<template>
    <v-dialog v-if="show" v-model="show" @close="show = false" fullscreen hide-overlay transition="dialog-bottom-transition">
        <loading v-model="loading" />
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="show = false">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>

                <v-toolbar-title class="text-center"> {{ $t('seleziona-campi') }} </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn v-if="noUnion" color="error" class="ml-6 mr-6" @click="$emit('convertLead')"> {{ $t('converti-senza-unire') }} </v-btn>
                    <v-btn dark color="success" @click="merge()"> {{ $t('unisci') }} </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-simple-table dense v-if="leads.length">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">{{ $t('elenco-campi') }}</th>
                            <template>
                                <th class="text-left" v-for="i in leads.map((k, i) => i)" :key="i">
                                    <v-radio-group class="text-center" row v-model="selectAllLeads" @change="changeAllSelect()">
                                        <v-radio
                                            :label="`${types[i] == 'leads' ? 'Lead' : 'Lead da controllare'} ${leads[i].ischild ? ' - figlio' : ''}`"
                                            :value="i"
                                        ></v-radio>
                                    </v-radio-group>
                                </th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="f in fields" :key="f.label">
                            <!--titolo-->
                            <td>
                                <h3>{{ f.label }}</h3>
                            </td>

                            <template v-if="f.field != 'Opening' && f.field != 'Social'">
                                <template>
                                    <td class="justify-center" v-for="i in leads.map((k, i) => i)" :key="i">
                                        <v-radio-group class="text-center" row v-model="result[f.field]">
                                            <v-radio class="" label="" :value="i"></v-radio>
                                            <template v-if="f.field == 'City' || f.field == 'Province'">
                                                {{ leads[i][f.field].name || $t('vuoto') }}
                                            </template>
                                            <template v-else>
                                                {{ leads[i][f.field] || $t('vuoto') }}
                                            </template>
                                        </v-radio-group>
                                    </td>
                                </template>
                            </template>
                            <!--radio panel-->
                            <template v-if="f.field == 'Social' || f.field == 'Opening'">
                                <template>
                                    <td class="justify-center" style="max-width: 300px" v-for="i in leads.map((k, i) => i)" :key="i">
                                        <v-row>
                                            <v-col cols="1">
                                                <v-radio-group row v-model="select[f.field]" @change="changeObjectSelect(f.field)">
                                                    <v-radio :value="i"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                            <v-col cols="11" class="d-flex align-center">
                                                <v-expansion-panels flat v-model="panel[f.field]">
                                                    <v-expansion-panel>
                                                        <v-expansion-panel-header>
                                                            {{ $t('apri-per-dettagli') }}
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content class="fixed-height-panel">
                                                            <v-row v-for="(value, key) in leads[i][f.field]" :key="key">
                                                                <template v-if="key != 'id' && !['sat', 'sun'].includes(key)">
                                                                    <v-col>
                                                                        <template v-if="f.field == 'Opening'">
                                                                            {{ days.find((x) => x.value == key).text }}
                                                                        </template>
                                                                        <template v-if="f.field == 'Social'">
                                                                            {{ key }}
                                                                        </template>
                                                                    </v-col>
                                                                    <v-col class="text-right">
                                                                        <v-radio-group class="text-center" row v-model="result[f.field][key]">
                                                                            <v-radio class="" label="" :value="i"></v-radio>
                                                                            <template v-if="f.field == 'Opening'"> {{ getDayOpening(value) }} </template>
                                                                            <template v-if="f.field == 'Social'">
                                                                                {{ value || $t('vuoto') }}
                                                                            </template>
                                                                        </v-radio-group>
                                                                    </v-col>
                                                                </template>
                                                            </v-row>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </v-col>
                                        </v-row>
                                    </td>
                                </template>
                            </template>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-col cols="12" class="text-center">
                <v-btn color="primary" class="ma-6 justify-center" @click="merge()" x-large> {{ $t('unisci') }} </v-btn>
            </v-col>
        </v-card>
    </v-dialog>
</template>

<script>
import loading from '@/components/loading.vue'
export default {
    name: 'Merge',
    components: { loading },
    data: function () {
        return {
            loading: false,
            leads: [],
            result: {
                Name: 0,
                Phone: 0,
                Email: 0,
                Address: 0,
                CAP: 0,
                Opening: { mon: 0, tue: 0, wed: 0, thu: 0, fri: 0, sat: 0, sun: 0 },
                Social: { facebook: 0, instagram: 0, youtube: 0, linkedin: 0, twitter: 0, tiktok: 0 },
                Category: 0,
                Url: 0,
                PIVA: 0,
                CF: 0,
                Province: 0,
                City: 0,
                tags: 0
            },
            valid: true,
            fields: [
                { label: this.$t('nome'), field: 'Name' },
                { label: this.$t('telefono'), field: 'Phone' },
                { label: this.$t('email'), field: 'Email' },
                { label: this.$t('indirizzo'), field: 'Address' },
                { label: this.$tc('provincia', 1), field: 'Province' },
                { label: this.$t('citta'), field: 'City' },
                { label: this.$t('cap'), field: 'CAP' },
                { label: this.$tc('orario', 2), field: 'Opening' },
                { label: this.$t('social'), field: 'Social' },
                { label: this.$t('categoria'), field: 'Category' },
                { label: this.$t('sito'), field: 'Url' },
                { label: this.$t('partita-iva'), field: 'PIVA' },
                { label: this.$t('codice-fiscale'), field: 'CF' }
            ],
            selectAllLeads: 0,
            select: { Social: 0, Opening: 0 },
            panel: { Social: null, Opening: null },
            days: [
                { text: this.$t('lunedi'), value: 'mon', sort: 1 },
                { text: this.$t('martedi'), value: 'tue', sort: 2 },
                { text: this.$t('mercoledi'), value: 'wed', sort: 3 },
                { text: this.$t('giovedi'), value: 'thu', sort: 4 },
                { text: this.$t('venerdi'), value: 'fri', sort: 5 },
                { text: this.$t('sabato'), value: 'sat', sort: 6 },
                { text: this.$t('domenica'), value: 'sun', sort: 7 }
            ]
        }
    },
    props: {
        value: { type: Boolean },
        pid: { type: Array },
        types: { type: Array },
        details: { type: Array },
        noUnion: { type: Boolean, default: false }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    watch: {
        pid(val) {
            if (this.show && val.length) {
                this.start()
            }
        }
    },

    async created() {},

    methods: {
        async start() {
            this.loading = true
            let leads = []
            try {
                for (let i = 0; i <= this.pid.length - 1; i++) {
                    let pop = [
                        'Social',
                        'Opening',
                        'Opening.mon',
                        'Opening.tue',
                        'Opening.wed',
                        'Opening.thu',
                        'Opening.fri',
                        'Opening.sat',
                        'Opening.sun',
                        'parent_lead'
                    ]
                    if (this.types[i] == 'leads') {
                        pop.push('Province', 'City', 'tags')
                    }
                    const response = await this.$http.get(`${this.types[i]}/${this.pid[i]}`, {
                        populate: pop
                    })

                    if (!response || !response.data) {
                        console.error('Risposta API non valida:', response)
                        continue
                    }

                    const doc = response.data

                    let lead = {
                        id: doc.id,
                        type: this.types[i],
                        Name: doc.attributes.Name,
                        Phone: doc.attributes.Phone,
                        Email: doc.attributes.Email,
                        Address: doc.attributes.Address,
                        CAP: doc.attributes.CAP,
                        Opening: Object.fromEntries(
                            Object.entries(doc.attributes.Opening)
                                .filter((x) => x[0] != 'id' && x[0] != 'sat' && x[0] != 'sun')
                                .sort((a, b) => this.days.find((x) => x.value == a[0]).sort - this.days.find((x) => x.value == b[0]).sort)
                        ),
                        Social: doc.attributes.Social,
                        Longitude: doc.attributes.Longitude,
                        Latitude: doc.attributes.Latitude,
                        Category: doc.attributes.Category || '',
                        ischild: doc.attributes.parent_lead && doc.attributes.parent_lead.data !== null ? true : false,
                        isparent: doc.attributes.child_leads && doc.attributes.child_leads.data.length ? true : false,
                        Url: doc.attributes.Url,
                        PIVA: doc.attributes.PIVA,
                        CF: doc.attributes.CF
                    }

                    if (lead.type == 'leads') {
                        if (doc.attributes.Province) {
                            lead.Province = { id: doc.attributes.Province.data.id, name: doc.attributes.Province.data.attributes.Name }
                        } else {
                            lead.Province = { id: null, name: null }
                        }
                        if (doc.attributes.City.data) {
                            lead.City = {
                                id: doc.attributes.City.data.id,
                                name: doc.attributes.City.data.attributes.Name,
                                cap: doc.attributes.City.data.attributes.Cap
                            }
                        } else {
                            lead.City = { id: null, name: null, cap: [] }
                        }
                        lead.tags = doc.attributes.tags.data
                    } else {
                        lead.Province = this.details[i].p
                        lead.City = this.details[i].ci
                        lead.CAP = this.details[i].ca
                        lead.tags = []
                    }
                    leads.push(lead)
                }

                this.leads = leads
            } catch (error) {
                console.error('Errore durante il caricamento dei lead:', error)
                // Mostra una notifica di errore all'utente
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-caricamento-dati'),
                    type: 'error'
                })
            } finally {
                this.loading = false
            }
        },

        getDayOpening(value) {
            if (value.morningClosed && value.afternoonClosed) {
                return this.$t('chiuso')
            } else if (!value.moTimeOpen && !value.afternoonClosed && !value.afTimeOpen && !value.morningClosed) {
                return this.$t('non-ci-sono-orari')
            } else {
                let mo = null
                let af = null
                if (value.morningClosed) {
                    mo = this.$t('chiuso')
                } else {
                    mo = `${this.$moment(value.moTimeOpen, 'HH:mm:ss.SSS').format('HH:mm')} - ${
                        value.moTimeClose ? this.$moment(value.moTimeClose, 'HH:mm:ss.SSS').format('HH:mm') : ''
                    } `
                }
                if (value.afternoonClosed) {
                    af = this.$t('chiuso')
                } else {
                    af = `${value.afTimeOpen ? this.$moment(value.afTimeOpen, 'HH:mm:ss.SSS').format('HH:mm') : ''} - ${this.$moment(
                        value.afTimeClose,
                        'HH:mm:ss.SSS'
                    ).format('HH:mm')} `
                }
                return `${mo} , ${af}`
            }
        },
        changeObjectSelect(field) {
            Object.keys(this.result[field]).forEach((key) => {
                this.result[field][key] = this.select[field]
            })
        },
        changeAllSelect() {
            Object.keys(this.result).forEach((key) => {
                if (typeof this.result[key] === 'object') {
                    Object.keys(this.result[key]).forEach((keyin) => {
                        this.result[key][keyin] = this.selectAllLeads
                    })
                } else {
                    this.result[key] = this.selectAllLeads
                }
            })
            this.select = { Social: this.selectAllLeads, Opening: this.selectAllLeads }
        },
        async merge() {
            try {
                let lead = {}

                Object.keys(this.result).forEach((key) => {
                    const selectedIndex = this.result[key]
                    if (key === 'Address') {
                        lead['Address'] = this.leads[selectedIndex].Address
                        lead['Latitude'] = this.leads[selectedIndex].Latitude
                        lead['Longitude'] = this.leads[selectedIndex].Longitude
                    } else if (key === 'Opening') {
                        // Gestione speciale per Opening
                        lead[key] = {}
                        Object.keys(this.result[key]).forEach((day) => {
                            const daySelectedIndex = this.result[key][day]
                            lead[key][day] = this.leads[daySelectedIndex].Opening[day] || {}
                        })
                    } else if (key === 'Social') {
                        // Gestione simile per Social
                        lead[key] = {}
                        Object.keys(this.result[key]).forEach((social) => {
                            const socialSelectedIndex = this.result[key][social]
                            lead[key][social] = this.leads[socialSelectedIndex].Social[social] || null
                        })
                    } else if (key === 'Province' || key === 'City') {
                        lead[key] = this.leads[selectedIndex][key].id
                    } else {
                        lead[key] = this.leads[selectedIndex][key]
                    }
                })
                // Merge tags
                let tags = new Set()
                this.leads.forEach((l) => {
                    if (l?.tags?.length) {
                        l.tags.forEach((t) => {
                            if (t?.id) {
                                tags.add(t.id)
                            }
                        })
                    }
                })
                lead.tags = { set: [...tags] }

                // Trova il lead padre e i lead figli
                const parentLead = this.leads.find((l) => l.isparent == true)
                const parentId = parentLead ? parentLead.id : null

                // Aggiorna il lead padre
                await this.$http.put(`leads/${this.pid[0]}`, {
                    data: { ...lead, Edited: true }
                })

                // lead to check
                for (let i = 0; i < this.leads.length; i++) {
                    try {
                        // Salta il lead padre
                        if (this.leads[i].isparent) {
                            continue
                        } else if (this.types[i] == 'leadToChecks') {
                            await this.$http.put(`leadToChecks/${this.pid[i]}`, {
                                data: { disabled: true }
                            })
                            continue
                            // lead figli
                        } else if (this.leads[i].ischild) {
                            // Prima aggiorna i plan
                            const docs = await this.$http.get('plans', {
                                filters: {
                                    lead: { id: { $eq: this.pid[i] } }
                                },
                                sort: ['order']
                            })
                            if (docs?.data.length) {
                                await Promise.all(
                                    docs.data.map(async (x) => {
                                        return this.$http.put(`plans/${x.id}`, {
                                            data: {
                                                lead: { id: this.pid[0] }
                                            }
                                        })
                                    })
                                )
                            }

                            // Elimina il lead figlio
                            await this.$http.delete(`${this.types[i]}/${this.pid[i]}`)
                        }
                    } catch (error) {
                        console.error(`Errore nella gestione del lead ${this.pid[i]}:`, error)
                    }
                }

                this.$notify({
                    group: 'notifica',
                    title: this.$t('merge'),
                    text: this.$t('merge-avvenuto-con-successo'),
                    type: 'success'
                })

                this.$router.push(`lead/${this.pid[0]}`)
            } catch (error) {
                console.error('Errore durante il merge:', error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('merge'),
                    text: this.$t('errore-nel-merge'),
                    type: 'error'
                })
            }
        }
    }
}
</script>

<style scoped>
.custom-margin {
    margin-right: -500px;
}
.fixed-height-panel {
    max-height: 700px;
    overflow-y: auto;
}
</style>
